import React, { useEffect } from "react";
import styled from "styled-components";
import { BasicLayout } from "../../../layouts/BasicLayout";
import { mobileSize } from "../../../util/variables";
import { AppLogo, AppTitle, AppText, AppButton } from "../../../components/ui";
import Img from "gatsby-image";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { gift_page_view, next_page_view } from "../../../services/mixpanel/gift-flow-events";

const Start = () => {
  useEffect(() => {
    gift_page_view("GiftRedeemStart");
  }, []);

  const handleGetStartedClick = () => {
    next_page_view("GiftRedeemStart");
    navigate("/gift/claim/join");
  };

  const imageData = useStaticQuery(graphql`
    query giftRedeemed {
      gift: file(relativePath: { eq: "gift/gift-open.webp" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <BasicLayout>
      <Container>
        <LogoContainer>
          <AppLogo />
        </LogoContainer>
        <LogoContainerMobile>
          <AppLogo size="x-small" />
        </LogoContainerMobile>
        <ImgContainer>
          <Img fluid={imageData.gift.childImageSharp.fluid} />
        </ImgContainer>
        <H1 style={{ color: "#10130C" }}>Claim Your Gift</H1>
        <AppText
          fontSize={24}
          mobileFontSize={18}
          textAlign="center"
          style={{ maxWidth: "32ch", margin: "0 auto 16px auto", color: "#10130C" }}
        >
          You&apos;ve received a year of access to Imprint. Follow a few quick steps to create or
          log into your Imprint account and redeem your gift.
        </AppText>
        <StyledAppButton onClick={handleGetStartedClick}>Get Started</StyledAppButton>
      </Container>
    </BasicLayout>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100svh;
  padding: 80px 64px;
  gap: 32px;
  @media ${mobileSize} {
    padding: 40px;
    gap: 0;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 100px;
  top: 80px;
  display: block;
  @media ${mobileSize} {
    display: none;
  }
`;

const LogoContainerMobile = styled.div`
  display: none;
  @media ${mobileSize} {
    position: static;
    /* margin-top: 0px; */
    display: block;
    left: 100px;
    top: 80px;
  }
`;

const H1 = styled(AppTitle as any)`
  text-align: center;
  margin: 0;
  /* margin-bottom: 16px; */
  /* margin-top: 32px; */
  @media ${mobileSize} {
    margin: 40px 0 16px 0;
    font-size: 36px;
  }
`;

const ImgContainer = styled.div`
  max-width: 365px;
  width: 100%;
  margin-top: 56px;
  @media ${mobileSize} {
    margin-top: 32px;
    max-width: 295px;
  }
`;

const StyledAppButton = styled(AppButton as any)`
  margin-top: 32px;
  @media ${mobileSize} {
    margin-top: auto;
    margin-bottom: 32px;
  }
`;

export default Start;
