import { ProviderT } from "./acquisition-events";
import { trackEvent } from "./mixpanel";

type GiftScreen =
  | "GiftStart"
  | "GiftPurchase"
  | "GiftRedeem"
  | "GiftRedeemStart"
  | "GiftPurchaseComplete"
  | "GiftRedeemComplete"
  | "GiftLogin"
  | "GiftPersonalize"
  | "GiftingStart";

const trackGiftEvent = (event: string, properties: object = {}) => {
  trackEvent(event, { ...properties }, "gift");
};

export const web_acq_create_account_attempt = (Provider: ProviderT) => {
  trackGiftEvent("WebAcqCreateAccountAttempt", {
    Provider,
  });
};

export const web_acq_create_account_success = (Provider: ProviderT) => {
  trackGiftEvent("WebAcqCreateAccountSuccess", {
    Provider,
  });
};

export const web_acq_create_account_failure = (Provider: ProviderT) => {
  trackGiftEvent("WebAcqCreateAccountFailure", {
    Provider,
  });
};

export const gift_page_view = (screen: GiftScreen) => {
  trackGiftEvent("WebGift_PageView", {
    Screen: screen,
    url: window.location.href,
  });
};

export const next_page_view = (screen: GiftScreen) => {
  trackGiftEvent("WebGift_NextPageView", {
    Screen: screen,
    url: window.location.href,
  });
};

// GIFT PURCHASE EVENTS

export const gift_purchase_start = () => {
  trackGiftEvent("WebGiftPurchase_Start", {
    Screen: "GiftStart",
  });
};

// GIFT REDEEM EVENTS
export const gift_redeem_get_started = () => {
  trackGiftEvent("WebGiftRedeem_GetStarted", {
    Screen: "GiftRedeem",
  });
};

export const gift_redeem_emailSwitch = () => {
  trackGiftEvent("WebGiftRedeem_EmailSwitched_Tapped", {
    Screen: "GiftRedeem",
  });
};
